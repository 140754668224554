<template>
  <b-card no-body>
    <b-card-header>
      <b-row>
        <b-col>
          <p>Showing {{ start }} - {{ end }} of {{ total }}</p>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-card-header>

    <b-table
      striped
      hover
      small
      sticky-header="300px"
      :fields="fields"
      :items="messages"
      primary-key="id"
      :tbody-tr-class="rowClass"
      @row-clicked="selectMessage"
    >
      <template #cell()="data">
        <strong v-if="!isViewed(data.item)">{{ data.value }}</strong>
        <span v-else>{{ data.value }}</span>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import { bus } from '@/main'
import UserService from '@/services/UserService'

export default {
  name: 'MessageList',
  data () {
    return {
      selectedMessageId: null,
      page: 1,
      perPage: 10,
      pages: 1,
      fields: [
        { key: 'company', sortable: true },
        { key: 'email', sortable: true },
        { key: 'subject', sortable: true },
        { key: 'created', sortable: true }
      ],
      user: null
    }
  },

  props: {
    messages: {
      type: Array,
      default: () => { return [] }
    },

    total: {
      type: [Number, String],
      default: 0
    }
  },

  async created () {
    this.user = await UserService.getUserInfo()
  },

  methods: {
    selectMessage (data, idx) {
      if (data.viewed === null) {
        data.viewed = true
        bus.$emit('messageRead')
      }
      this.selectedMessageId = +data.id
      this.$emit('selectMessage', data)
    },

    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (!this.selectedMessageId) return
      if (+item.id === +this.selectedMessageId) return 'bg-secondary'
    },

    isViewed (item) {
      if (item.viewed) {
        return true
      } else {
        return (item.senderrole.localeCompare('Client') !== 0 && (this.user.isBookkeeper || this.user.isAdmin))
      }
    }
  },

  computed: {
    /**
     * @returns {Number}
     */
    start: function () {
      return ((+this.page - 1) * +this.perPage) + 1
    },
    /**
     * @returns {Number}
     */
    end: function () {
      return ((+this.page * +this.perPage) > +this.total) ? +this.total : (+this.page * +this.perPage)
    }
  }
}
</script>
