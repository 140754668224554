<template>
  <b-form v-if="!loading" @submit.prevent="send" @reset.prevent="reset">
    <b-form-group
      v-if="companies && companies.length"
      label="Company:"
      label-for="company"
      label-cols="4"
      label-cols-sm="3"
      label-cols-md="2"
      label-align="right"
    >
      <b-form-select
        v-model="message.company_id"
        :options="companies"
        :disabled="companies.length === 1 && +message.company_id > 0"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >-- Please select a company --</b-form-select-option
          >
        </template>
      </b-form-select>
    </b-form-group>

    <b-form-group
      label="Subject:"
      label-for="subject"
      label-cols="4"
      label-cols-sm="3"
      label-cols-md="2"
      label-align="right"
    >
      <b-form-input
        id="subject"
        v-model="message.subject"
        type="text"
        placeholder="Subject of Message"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Message:"
      label-for="message"
      label-cols="4"
      label-cols-sm="3"
      label-cols-md="2"
      label-align="right"
    >
      <Editor v-model="message.content" api-key="83u69vpf23jbsp27k3ufq78a7fp4jxwdd5xhrtbkhpueukuu" :init="tinymceConfig"></Editor>
    </b-form-group>

    <b-form-group
      label="Documents:"
      label-for="documents"
      label-cols="4"
      label-cols-sm="3"
      label-cols-md="2"
      label-align="right"
    >
      <b-form-file v-model="message.files" multiple> </b-form-file>
    </b-form-group>

    <b-row align-h="center" align="right" class="modal-footer">
      <b-col cols="12">
        <b-button type="reset" variant="danger" class="ml-1">
          <b-icon icon="arrow-clockwise" />
          Reset
        </b-button>
        <b-button type="button" @click="$emit('close')" variant="secondary" class="ml-1">
          <b-icon icon="x" />
          Cancel
        </b-button>
        <b-button type="submit" variant="primary" class="ml-1">
          <b-icon icon="check" />
          Send
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import UserService from '@/services/UserService'
import MessageService from '@/services/MessageService'
import Editor from '@tinymce/tinymce-vue'
import AdminService from '@/services/AdminService'

export default {
  name: 'Compose',
  components: { Editor },

  props: {
    companyId: {
      type: [String, Number],
      default: 0
    },

    /* The parent message of the message thread we're replying to */
    messageId: {
      type: [String, Number],
      default: 0
    },

    /* The plaid transaction ID this message if regarding, if applicable */
    transactionId: {
      type: [String, Number],
      default: 0
    }
  },

  data () {
    return {
      user: {},
      companies: [],
      parent: null,
      loading: false,
      message: {
        company_id: null,
        subject: null,
        content: null,
        files: []
      },

      tinymceConfig: {
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
      }
    }
  },

  async mounted () {
    this.user = await UserService.getUserInfo()
    let companies = null
    if (UserService.isAdmin()) {
      companies = await AdminService.getCompanies()
    } else {
      companies = await UserService.getCompanies()
    }
    if (companies && companies.length) {
      if (companies.length === 1) {
        this.message.company_id = +companies[0].id
      } else if (+this.companyId > 0) {
        this.message.company_id = +this.companyId
      }
      companies.forEach((c) => {
        this.companies.push({ value: +c.id, text: c.name })
      })

      this.companies = this.companies.sort((a, b) => ('' + a.text).localeCompare(b.text))
    }
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      const data = {
        message_id: +this.messageId,
        plaidtransaction_id: +this.transactionId
      }
      MessageService.initialize(data).then((response) => {
        if (response && response.data.result && response.data.info.message) {
          this.parent = response.data.info.message
        }
      }).catch((err) => {
        this.$aimNotify.notify(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    send () {
      this.loading = true
      const formData = new FormData()
      formData.append('company_id', this.message.company_id)
      formData.append('subject', this.message.subject)
      formData.append('content', this.message.content)
      if (this.messageId) {
        formData.append('parent_id', this.messageId)
      }
      if (this.transactionId) {
        formData.append('plaidtransaction_id', this.transactionId)
      }
      if (this.message.files && this.message.files.length) {
        this.message.files.forEach((f) => {
          formData.append('files[]', f, f.name)
        })
      }

      MessageService.send(formData).then((response) => {
        this.$aimNotify.notify(response)
        this.$emit('close')
      }).catch((err) => {
        this.$aimNotify.error(err.response)
        console.dir(err)
      }).finally(() => {
        this.loading = false
      })
    },

    reset () {
      this.message.subject = null
      this.message.content = null
      this.message.files = []
    }
  }
}
</script>
