<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        class="text-md-left text-center"
      >
        <span class="h4 text--white">Messages</span>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        offset-lg="4"
        xl="3"
        offset-xl="6"
        class="text-right"
      >
        <b-button
          variant="success"
          @click.prevent="showCompose = true"
        >
          New Message
        </b-button>
      </b-col>
    </b-row>
    <MessageList
      :messages="messages"
      :total="total"
      @selectMessage="selectMessage"
      @compose="showCompose = true"
    >
    </MessageList>

    <MessageView
      v-if="selectedMessage"
      :message-id="+selectedMessage.id"
      @reply="showReply = true"
    ></MessageView>

    <!-- Compose New Message modal -->
    <b-modal
      hide-footer
      id="compose"
      v-model="showCompose"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-title> Compose New Message </template>
      <Compose @close="$bvModal.hide('compose')"></Compose>
    </b-modal>

    <!-- Reply to Message modal -->
    <b-modal
      hide-footer
      id="reply"
      v-model="showReply"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-title> Reply To {{ selectedMessage.company }} </template>
      <Compose
        :company-id="+selectedMessage.company_id"
        :message-id="+selectedMessage.id"
        :transaction-id="+selectedMessage.plaidtransaction_id"
        @close="$bvModal.hide('reply')"
      ></Compose>
    </b-modal>
  </b-container>
</template>
<script>
import MessageService from '@/services/MessageService'
import MessageList from '@/components/messaging/List'
import MessageView from '@/components/messaging/View'
import Compose from '@/components/messaging/Compose'

export default {
  name: 'Messaging',
  components: { Compose, MessageList, MessageView },
  data () {
    return {
      loading: false,
      messages: [],
      total: 0,
      selectedMessage: { id: 0, company_id: 0, plaidtransaction_id: 0 },
      showCompose: false,
      showReply: false
    }
  },

  mounted () {
    this.load()
  },

  methods: {
    load () {
      if (this.loading) {
        return false
      }
      this.loading = true
      MessageService.list().then((response) => {
        if (response && response.data.result) {
          this.messages = response.data.info.latest
          this.total = +response.data.info.total
        }
      }).finally(() => {
        this.loading = false
      })
    },

    selectMessage (value) {
      this.selectedMessage = value
    }
  }
}
</script>
