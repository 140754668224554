<template>
  <b-overlay
    :show="loading"
    v-if="message && message.id"
  >
    <b-card
      no-body
      class="my-3 pb-3"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-table-simple
            small
            class="text-left mt-3"
          >
            <b-tr>
              <b-td class="text-right">Company:</b-td>
              <b-td>{{ message.company }}</b-td>
            </b-tr>
            <b-tr>
              <b-td class="text-right">From:</b-td>
              <b-td>{{ message.email }}</b-td>
            </b-tr>
            <b-tr>
              <b-td class="text-right">To:</b-td>
              <b-td>{{ message.recipient }}</b-td>
            </b-tr>
            <b-tr>
              <b-td class="text-right">Date:</b-td>
              <b-td>{{ message.created }}</b-td>
            </b-tr>
          </b-table-simple>

          <div v-if="message.documents && message.documents.length">
            <hr />
            <h4 class="text-center m-0">Attachments</h4>
            <ul class="text-left ml-5">
              <li
                v-for="d in message.documents"
                :key="d.id"
              >
                <a
                  :href="d.url"
                  target="_blank"
                >{{ d.documentname }}</a>
              </li>
            </ul>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="8"
          class="text-left"
        >
          <b-card no-body>
            <b-card-header>
              <h4 class="m-0">Subject: {{ message.subject }}</h4>
            </b-card-header>
            <b-card-text class="m-3">
              <span v-html="message.content"></span>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button
            variant="success"
            @click.prevent="$emit('reply', message)"
          >Reply
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import MessageService from '@/services/MessageService'

export default {
  name: 'MessageView',

  props: {
    messageId: {
      type: [Number, String]
    }
  },

  data () {
    return {
      loading: false,
      message: {}
    }
  },

  mounted () {
  },

  methods: {
    load () {
      if (this.loading) {
        return false
      }
      this.loading = true
      MessageService.get(+this.messageId).then((response) => {
        this.message = response.data.info
      }).finally(() => {
        this.loading = false
      })
    }
  },

  watch: {
    messageId: {
      immediate: true,
      handler (newId) {
        if (newId) {
          this.load()
        }
      }
    }
  }
}
</script>
